import React from "react";
import MarkdownIt from "markdown-it";

export const RenderHTML = ({ content, className = "stf-rendered-content" }) => (
  <div className={className} dangerouslySetInnerHTML={{ __html: content }} />
);

export const RenderMD = ({ content, className = "stf-rendered-content" }) => (
  <div
    className={className}
    dangerouslySetInnerHTML={{ __html: MarkdownIt().render(content) }}
  />
);
